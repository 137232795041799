import React from 'react';
import { Route, Routes } from 'react-router';
import { PointsAssistContextProvider } from './context/points-assist-context';
import { RedirectRoute } from './components/route/redirect-route';
import { LogRoute } from './components/route/log-route';
import { AuthorizedRoute } from './components/route/authorized-route';
//////////////////////
///////////////////////////////////////////////////////////////////////////////
/////////
import { BrandRoutes } from './routes-fpr'; // eslint-disable-line no-redeclare
//////////
var DashboardView = React.lazy(function () { return import('./views-common/dashboard-view/dashboard-view'); });
var RewardsHistoryView = React.lazy(function () { return import('./views-common/rewards-history-view/rewards-history-view'); });
var FaqView = React.lazy(function () { return import('./views-common/faq-view/faq-view'); });
var MaintenanceBreakView = React.lazy(function () { return import('./views-common/maintenance-break-view/maintenance-break-view'); });
var ExpiredRewardView = React.lazy(function () { return import('./views-common/maintenance-break-view/expired-reward-view'); });
var ClaimedRewardView = React.lazy(function () { return import('./views-common/maintenance-break-view/claimed-reward-view'); });
var ParticipatingDealersView = React.lazy(function () { return import('./views-common/participating-dealers-view/participating-dealers-view'); });
var PointsAssistView = React.lazy(function () { return import('./views-common/points-assist-view/components/points-assist/points-assist'); });
import { DigitalFulfillmentContextProvider } from './context/digital-fulfillment-context';
import { CheckoutContextProvider } from './context/checkout-context';
import SurpriseAndDelightView from './views-common/rewardsV2-view/suprise-delight-view/surprise-delight-view';
var RewardsDashboardView = React.lazy(function () { return import('./views-common/rewardsV2-view/dashboard-view/rewards-dashboard-view'); });
var RewardsDetailsView = React.lazy(function () { return import('./views-common/rewardsV2-view/details-view/reward-details-view'); });
var RewardsCheckoutView = React.lazy(function () { return import('./views-common/rewardsV2-view/checkout-view/rewards-checkout-view'); });
var RewardsCompleteView = React.lazy(function () { return import('./views-common/rewardsV2-view/complete-view/complete-view'); });
var RewardsErrorView = React.lazy(function () { return import('./views-common/rewardsV2-view/error-pages/rewards-error-page-view'); });
var CreditCardLandingView = React.lazy(function () { return import('./views-common/credit-card-view/credit-card-landing-view'); });
var VisaCreditCardPrefillView = React.lazy(function () { return import('./views-common/credit-card-view/credit-card-prefill-view/credit-card-prefill-view'); });
var CreditCardPrequalCheckView = React.lazy(function () { return import('./views-common/credit-card-view/credit-card-prequal-check-view/credit-card-prequal-check-view'); });
var ActivateAccountView = React.lazy(function () { return import('./views-common/activate-account-view/activate-account-view'); });
export var CommonRoutes = function () {
    return (React.createElement(Routes, null,
        React.createElement(Route, { element: React.createElement(LogRoute, null) },
            React.createElement(Route, { path: 'credit-card', element: React.createElement(CreditCardLandingView, null) }),
            React.createElement(Route, { path: 'credit-offer-a', element: React.createElement(CreditCardLandingView, null) }),
            React.createElement(Route, { path: 'faqs', element: React.createElement(FaqView, null) }),
            React.createElement(Route, { path: 'maintenance-break', element: React.createElement(MaintenanceBreakView, null) }),
            React.createElement(Route, { path: 'expired-reward', element: React.createElement(ExpiredRewardView, null) }),
            React.createElement(Route, { path: 'claimed-reward', element: React.createElement(ClaimedRewardView, null) }),
            React.createElement(Route, { path: 'time-out', element: React.createElement(MaintenanceBreakView, null) }),
            React.createElement(Route, { path: 'landing', element: React.createElement(RedirectRoute, { to: '/overview' }) }),
            React.createElement(Route, { path: 'how-to-redeem', element: React.createElement(RedirectRoute, { to: '/overview' }) }),
            React.createElement(Route, { path: 'offers-and-promotions', element: React.createElement(RedirectRoute, { to: '/rewards' }) }),
            React.createElement(Route, { path: 'offers', element: React.createElement(RedirectRoute, { to: '/rewards' }) }),
            React.createElement(Route, { path: 'collection', element: React.createElement(RedirectRoute, { to: '/rewards' }) }),
            React.createElement(Route, { path: 'how-to-earn', element: React.createElement(RedirectRoute, { to: '/overview', scrollTo: 'earn' }) }),
            React.createElement(Route, { path: 'lar-sd-campaigns/sd-lar-benefit', element: React.createElement(RedirectRoute, { to: '/rewards/special', scrollTo: 'SD_LAR_BENEFIT' }) }),
            React.createElement(Route, { element: React.createElement(AuthorizedRoute, null) },
                React.createElement(Route, { path: 'dashboard', element: React.createElement(DashboardView, null) }),
                React.createElement(Route, { path: 'participating-dealers', element: React.createElement(ParticipatingDealersView, null) }),
                React.createElement(Route, { path: 'credit-offer-b', element: React.createElement(CreditCardPrequalCheckView, null) }),
                React.createElement(Route, { path: 'credit-card/precheck', element: React.createElement(CreditCardPrequalCheckView, null) }),
                React.createElement(Route, { path: 'credit-card/prefill', element: React.createElement(VisaCreditCardPrefillView, null) }),
                React.createElement(Route, { path: 'credit-card/prefill-prequal', element: React.createElement(VisaCreditCardPrefillView, null) }),
                React.createElement(Route, { path: 'credit-card/leaving-ford', element: React.createElement(RedirectRoute, { to: '/credit-card/precheck' }) }),
                React.createElement(Route, { path: 'credit-card/leaving-lincoln', element: React.createElement(RedirectRoute, { to: '/credit-card/precheck' }) }),
                React.createElement(Route, { path: 'activate-account', element: React.createElement(ActivateAccountView, null) })),
            React.createElement(Route, { path: 'rewards-history', element: React.createElement(AuthorizedRoute, { Context: PointsAssistContextProvider }) },
                React.createElement(Route, { index: true, element: React.createElement(RewardsHistoryView, null) }),
                React.createElement(Route, { path: 'points-assist', element: React.createElement(PointsAssistView, null) })),
            React.createElement(Route, { path: 'rewards', element: React.createElement(AuthorizedRoute, { Context: DigitalFulfillmentContextProvider }) },
                React.createElement(Route, { index: true, element: React.createElement(RewardsDashboardView, null) }),
                React.createElement(Route, { path: 'special', element: React.createElement(SurpriseAndDelightView, null) }),
                React.createElement(Route, { path: 'detail', element: React.createElement(RewardsDetailsView, null) }),
                React.createElement(Route, { path: 'checkout', element: React.createElement(AuthorizedRoute, { Context: CheckoutContextProvider }) },
                    React.createElement(Route, { index: true, element: React.createElement(RewardsCheckoutView, null) })),
                React.createElement(Route, { path: 'complete', element: React.createElement(RewardsCompleteView, null) }),
                React.createElement(Route, { path: 'error', element: React.createElement(RewardsErrorView, null) }))),
        React.createElement(Route, { path: '*', element: React.createElement(BrandRoutes, null) })));
};
