import seoService from '../services/seo-service/seo-service';
export function buildQueryParams(params) {
    var qs = [];
    for (var key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
            qs.push("".concat(encodeURIComponent(key), "=").concat(encodeURIComponent(params[key])));
        }
    }
    return "?".concat(qs.join('&'));
}
export var getTimeStamp = function () {
    return new Date().getTime().toString(36).toUpperCase();
};
export var generateRandomNum = function () {
    return Math.floor(10000000 + Math.random() * 80000000).toString();
};
export var generateTransactionNum = function (code) {
    return "".concat(generateRandomNum(), ":").concat(getTimeStamp(), ":").concat(code);
};
export var deDuplicateRewards = function (array) {
    var ids = array.map(function (_a) {
        var rewardCode = _a.rewardCode;
        return rewardCode;
    });
    return array.filter(function (a, index) { return !ids.includes(a.rewardCode, index + 1); });
};
export var setPageTitle = function () {
    var _a, _b, _c;
    var Capitalize = function (str) {
        var words = str.split(' ');
        return words
            .map(function (word) {
            var _a;
            return ((_a = word[0]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + (word === null || word === void 0 ? void 0 : word.substring(1));
        })
            .join(' ');
    };
    var title = (_c = (_b = (_a = window.location.pathname) === null || _a === void 0 ? void 0 : _a.replace(/.*?(fordpass-rewards|lincoln-access-rewards)/, '')) === null || _b === void 0 ? void 0 : _b.replace('/', '')) === null || _c === void 0 ? void 0 : _c.replace(/\W/g, ' ').trim();
    seoService.set({
        title: Capitalize(title).includes("Rewards") ? Capitalize(title) : "Rewards ".concat(Capitalize(title)),
    });
};
export var isMobileWidth = window.innerWidth < 995;
