/* istanbul ignore file */
import React, { useState } from 'react';
import { useAmplitude } from '../hooks/use-amplitude';
import { useNavigateTo } from '../hooks/use-navigateTo';
import { EXPIRED } from '../services/points-balance-service/points-balance-service';
export var APIHelperContext = React.createContext({
    hasError: null,
    handleError: null,
    handleFatalError: null,
});
export var APIHelperContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), hasError = _b[0], setHasError = _b[1];
    var _c = useState(''), errorMessage = _c[0], setErrorMessage = _c[1];
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var navigateTo = useNavigateTo().navigateTo;
    var handleError = function (error, serviceCode, reason, msg) {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = error === null || error === void 0 ? void 0 : error.response) === null || _a === void 0 ? void 0 : _a.status) == 503) {
            navigateTo('/maintenance-break');
            return;
        }
        var message = msg || ((_d = (_c = (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.message) || (error === null || error === void 0 ? void 0 : error.toString()) || 'none';
        logAmplitudeEvent("".concat(reason, " error"), {
            srvc: serviceCode,
            msg: message,
            data: JSON.stringify(((_e = error === null || error === void 0 ? void 0 : error.response) === null || _e === void 0 ? void 0 : _e.data) || 'none'),
            status: ((_f = error === null || error === void 0 ? void 0 : error.response) === null || _f === void 0 ? void 0 : _f.status) || 'none',
        });
        if (message)
            setErrorMessage(message);
        setHasError(serviceCode);
    };
    var handleFatalError = function (error, serviceCode, reason, msg) {
        if (window.location.href.includes('/maintenance-break'))
            return;
        if (window.location.href.includes('/time-out'))
            return;
        handleError(error, serviceCode, reason, msg);
        if (reason === EXPIRED) {
            navigateTo('/time-out', "error=".concat(reason));
        }
        else {
            navigateTo('/maintenance-break');
        }
    };
    return (React.createElement(APIHelperContext.Provider, { value: [
            { hasError: hasError, handleError: handleError, handleFatalError: handleFatalError, errorMessage: errorMessage, setErrorMessage: setErrorMessage },
            { hasError: hasError, handleError: handleFatalError, handleFatalError: handleFatalError, errorMessage: errorMessage, setErrorMessage: setErrorMessage },
        ] }, children));
};
