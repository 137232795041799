import React, { useState, useEffect, useContext } from 'react';
import DealerLookupService from '../services/dealer-lookup-service/dealer-lookup-service';
import VehicleService from '../services/vehicle-service/vehicle-service';
import { APIHelperContext } from './api-helper-context';
import ServiceHandler from '../services/service-handler';
import { useConfig } from '../hooks/use-content';
export var PointsAssistContext = React.createContext({
    dealers: [],
    vehicles: [],
});
export var PointsAssistContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), dealers = _b[0], setDealers = _b[1];
    var _c = useState([]), vehicles = _c[0], setVehicles = _c[1];
    var apiHelper = useContext(APIHelperContext)[0];
    var config = useConfig('points-assist-redirect-lookup')[0];
    var _d = useState(null), redirectLookup = _d[0], setRedirectLookup = _d[1];
    useEffect(function () {
        var dealerLookupService = new DealerLookupService();
        dealerLookupService.request(apiHelper).then(function (response) {
            var _a;
            if (response) {
                response.dealers.forEach(function (item) { return (item.search = ' ' + item.name.toUpperCase().replace(/[^A-Z ]/g, '')); });
                if ((_a = response.dealers) === null || _a === void 0 ? void 0 : _a.length)
                    setDealers(response.dealers);
            }
        });
    }, []);
    useEffect(function () {
        var vehicleService = new VehicleService();
        vehicleService.request(apiHelper).then(function (vehicles) {
            if (vehicles === null || vehicles === void 0 ? void 0 : vehicles.length)
                setVehicles(vehicles.filter(function (element) { return element.make === ServiceHandler.ConfigService.REWARD_PROGRAM; }));
        });
    }, []);
    useEffect(function () {
        setRedirectLookup(JSON.parse(config));
    }, [config]);
    return React.createElement(PointsAssistContext.Provider, { value: [{ dealers: dealers, vehicles: vehicles, setVehicles: setVehicles, redirectLookup: redirectLookup }] }, children);
};
