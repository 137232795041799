import Axios from 'axios';
import { buildQueryParams } from '../../utils/url.utils';
import CacheService from '../cache-service/cache-service';
var API_CACHE = 'apiCache';
// NOTE: copied from global-owner
var HttpService = /** @class */ (function () {
    function HttpService() {
    }
    HttpService.axiosCustomInstance = function (cachingEnabled) {
        var axiosInstance = Axios.create();
        axiosInstance.interceptors.request.use(function (config) { return config; });
        if (cachingEnabled) {
            axiosInstance.interceptors.response.use(function (data) {
                var key = data.config.url;
                if (data.config.params) {
                    key += buildQueryParams(data.config.params);
                }
                if (key) {
                    CacheService.putInCache(API_CACHE, {
                        key: key,
                        data: data,
                    });
                }
                return data;
            });
        }
        return axiosInstance;
    };
    HttpService.axios = function () {
        return this.axiosCustomInstance(false);
    };
    HttpService.get = function (apiUrl, cacheable, config) {
        if (cacheable) {
            var cachedResponse = CacheService.getFromCache(API_CACHE, config && config.params ? apiUrl + buildQueryParams(config.params) : apiUrl);
            return cachedResponse ? Promise.resolve(cachedResponse) : this.axiosCustomInstance(cacheable).get(apiUrl, config);
        }
        else {
            return this.axiosCustomInstance(cacheable).get(apiUrl, config);
        }
    };
    return HttpService;
}());
export default HttpService;
