var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LPS_API_PATH, SELECTED_VEHICLE_VIN_KEY } from '../../constants';
import Axios from 'axios';
import ServiceHandler from '../service-handler';
import { isMobile } from '../../services/mobile-bridge/mobile-bridge';
import { verifyAuthToken } from '../verify-auth-token/verify-auth-token';
var axios = Axios.create();
var DealerService = /** @class */ (function () {
    function DealerService() {
    }
    DealerService.prototype.request = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __awaiter(this, void 0, void 0, function () {
            var authService, cfg, payload, url, response, vin, e_1;
            return __generator(this, function (_l) {
                switch (_l.label) {
                    case 0:
                        authService = ServiceHandler.AuthenticationService;
                        cfg = ServiceHandler.ConfigService;
                        payload = {};
                        verifyAuthToken();
                        vin = (_a = localStorage.getItem(SELECTED_VEHICLE_VIN_KEY)) === null || _a === void 0 ? void 0 : _a.replace(/['']/g, '');
                        if (!!vin) return [3 /*break*/, 2];
                        if (!isMobile()) return [3 /*break*/, 2];
                        url = cfg.LPS_BASE_URL + LPS_API_PATH.VEHICLES;
                        return [4 /*yield*/, axios.get(url, {
                                headers: {
                                    'Auth-Token': authService.getCatBundle().access_token,
                                    'Application-Id': cfg.OWNER_APP_ID,
                                },
                            })];
                    case 1:
                        response = _l.sent();
                        vin = (_d = (_c = (_b = response.data.vehicles) === null || _b === void 0 ? void 0 : _b.$values) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.vin;
                        _l.label = 2;
                    case 2:
                        _l.trys.push([2, 7, , 8]);
                        if (!vin) return [3 /*break*/, 4];
                        url = cfg.LPS_BASE_URL + LPS_API_PATH.VEHICLES + vin + '/detail';
                        return [4 /*yield*/, axios.get(url, {
                                headers: {
                                    'Auth-Token': authService.getCatBundle().access_token,
                                    'Application-Id': cfg.OWNER_APP_ID,
                                },
                            })];
                    case 3:
                        response = _l.sent();
                        payload.dealerCupId = (_f = (_e = response.data) === null || _e === void 0 ? void 0 : _e.vehicle) === null || _f === void 0 ? void 0 : _f.preferredDealer;
                        _l.label = 4;
                    case 4:
                        if (!(payload.dealerCupId || payload.dealerPaCode)) return [3 /*break*/, 6];
                        url = cfg.LPS_BASE_URL + LPS_API_PATH.DEALER;
                        return [4 /*yield*/, axios.post(url, {
                                brand: ServiceHandler.ConfigService.BRAND_NAME,
                                countrycode: ServiceHandler.ConfigService.PROGRAM_COUNTRY,
                                cupid: payload.dealerCupId,
                                pa_code: payload.dealerPaCode,
                                device: { current_lat: -999.999, current_lng: -999.999 },
                                input_echo: false,
                                language: ServiceHandler.ConfigService.LANGUAGE_CODE,
                            }, {
                                headers: {
                                    'Auth-Token': authService.getCatBundle().access_token,
                                    'Application-Id': cfg.OWNER_APP_ID,
                                },
                            })];
                    case 5:
                        response = _l.sent();
                        payload.dealerUrl = (_k = (_j = (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.dealer) === null || _h === void 0 ? void 0 : _h.location) === null || _j === void 0 ? void 0 : _j.details) === null || _k === void 0 ? void 0 : _k.dealerWebsite;
                        return [2 /*return*/, payload];
                    case 6: return [3 /*break*/, 8];
                    case 7:
                        e_1 = _l.sent();
                        return [2 /*return*/, null];
                    case 8: return [2 /*return*/];
                }
            });
        });
    };
    return DealerService;
}());
export default DealerService;
