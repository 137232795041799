var STORE_TYPE;
(function (STORE_TYPE) {
    STORE_TYPE[STORE_TYPE["SESSION"] = 0] = "SESSION";
    STORE_TYPE[STORE_TYPE["LOCAL"] = 1] = "LOCAL";
})(STORE_TYPE || (STORE_TYPE = {}));
var CACHING_ENABLED = true;
var CACHE_EVICT_IN_MILLIS = 1 * 60 * 60 * 1000; // hours * minutes * seconds * millis
var DEFAULT_STORE = STORE_TYPE.SESSION;
var CacheService = /** @class */ (function () {
    function CacheService() {
    }
    CacheService.getOrCreateCache = function (cacheName) {
        if (!this.store.getItem(cacheName)) {
            this.store.setItem(cacheName, JSON.stringify({}));
        }
        var apiCache = this.store.getItem(cacheName);
        return apiCache ? JSON.parse(apiCache) : {};
    };
    CacheService.putInCache = function (cacheName, cacheEntry) {
        if (CACHING_ENABLED) {
            var key = cacheEntry.key, data = cacheEntry.data;
            var created = new Date().getTime();
            var expires = cacheEntry.expires ? cacheEntry.expires : new Date().getTime() + CACHE_EVICT_IN_MILLIS;
            var cache = this.getOrCreateCache(cacheName);
            cache[key] = {
                data: data,
                created: created,
                expires: expires,
            };
            this.store.setItem(cacheName, JSON.stringify(cache));
        }
    };
    CacheService.getFromCache = function (cacheName, key) {
        var cacheEntry = this.getOrCreateCache(cacheName)[key];
        if (cacheEntry) {
            if (cacheEntry.expires > new Date().getTime() - CACHE_EVICT_IN_MILLIS) {
                return cacheEntry.data;
            }
            else {
                this.evictFromCache(cacheName, key);
                return undefined;
            }
        }
        else {
            return undefined;
        }
    };
    CacheService.evictFromCache = function (cacheName, key) {
        var cache = this.getOrCreateCache(cacheName);
        delete cache[key];
        this.store.setItem(cacheName, JSON.stringify(cache));
    };
    CacheService.store = DEFAULT_STORE === STORE_TYPE.SESSION ? sessionStorage : localStorage;
    return CacheService;
}());
export default CacheService;
