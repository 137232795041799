import React, { useEffect, useState } from 'react';
import ServiceHandler from '../../services/service-handler';
import { Outlet } from 'react-router-dom';
import { useAmplitude } from '../../hooks/use-amplitude';
export var AuthorizedRoute = function (_a) {
    var _b = _a.Context, Context = _b === void 0 ? null : _b;
    var _c = useState(null), isAuthenticated = _c[0], setIsAuthenticated = _c[1];
    var authService = ServiceHandler.AuthenticationService;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    useEffect(function () {
        authService.onIsAuthenticated().then(function (isAuthenticated) {
            setIsAuthenticated(isAuthenticated);
            if (!isAuthenticated) {
                window['fma'].model.config.redirectUrl = window.location.href.replace(/(.*?)\?.*/, '$1');
                window['fma'].model.config.data.state = '';
                logAmplitudeEvent('authenticate');
                authService.login();
            }
        });
    }, [authService]);
    if (Context) {
        return isAuthenticated ? (React.createElement(Context, null,
            React.createElement(Outlet, null))) : (React.createElement("div", null));
    }
    else {
        return isAuthenticated ? React.createElement(Outlet, null) : React.createElement("div", null);
    }
};
