/* istanbul ignore file */
export default function cx() {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    var result = [];
    args.map(function (arg) {
        var argType = typeof arg;
        if (argType === 'string' || argType === 'number') {
            result.push(arg);
        }
        else if (argType === 'object') {
            for (var key in arg) {
                if (arg[key]) {
                    result.push(key);
                }
            }
        }
        return '';
    });
    return result.join(' ');
}
