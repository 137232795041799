// NOTE: copied from global-owner
var SeoService = /** @class */ (function () {
    function SeoService() {
    }
    SeoService.prototype.set = function (seoConfig) {
        if (seoConfig.title)
            document.title = seoConfig.title;
        if (seoConfig.metaDescription)
            document.getElementsByTagName('meta').namedItem('description').setAttribute('content', seoConfig.metaDescription);
    };
    return SeoService;
}());
export { SeoService };
var seoService = new SeoService();
export default seoService;
