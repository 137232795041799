import React from 'react';
import styles from './activity-indicator.module.scss';
import common from '../../../utils/common.module.scss';
import cx from '../../../utils/classnames';
export var ActivityIndicator = function (_a) {
    var _b;
    var white = _a.white, _c = _a.size, size = _c === void 0 ? 'L' : _c, className = _a.className;
    var wrapperClass = cx(styles.wrapper, common.mT3, (_b = {}, _b[styles.white] = white, _b[styles.small] = size === 'S', _b), className);
    return (React.createElement("div", { className: cx(wrapperClass, 'activityIndicator'), role: 'progressbar', title: 'progress bar' },
        React.createElement("div", { className: styles.line },
            React.createElement("svg", { className: styles.lineSvg, viewBox: '25 25 50 50' },
                React.createElement("circle", { className: 'activity-indicator ' + styles.lineIndeterminate, cx: '50', cy: '50', r: '23', fill: 'none', strokeMiterlimit: '10' })))));
};
