var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useContext, useEffect, useState } from 'react';
import { UserProfileContext } from './user-profile-context';
export var DigitalFulfillmentContext = React.createContext({
    code: null,
});
export var DigitalFulfillmentContextProvider = function (_a) {
    var children = _a.children;
    var userProfile = useContext(UserProfileContext)[0].userProfile;
    var _b = useState(''), phone = _b[0], setPhone = _b[1];
    var _c = useState({ street: '', apartment: '', city: '', state: '', zipCode: '' }), shippingAddress = _c[0], setShippingAddress = _c[1];
    var _d = useState({ street: '', apartment: '', city: '', state: '', zipCode: '' }), billingAddress = _d[0], setBillingAddress = _d[1];
    var _e = useState(true), billSameAsShip = _e[0], setBillSameAsShip = _e[1];
    useEffect(function () {
        var _a;
        if (userProfile) {
            userProfile['billToAddress'] = {};
            var initAddressVal = function (attribute, val) {
                shippingAddress[attribute] = val || '';
            };
            setPhone(((_a = userProfile.phoneNumber) === null || _a === void 0 ? void 0 : _a.replace(/\D/g, '')) || '');
            initAddressVal('street', userProfile.address1);
            initAddressVal('apartment', userProfile.address2);
            initAddressVal('city', userProfile.city);
            initAddressVal('state', userProfile.state);
            initAddressVal('zipCode', (userProfile.zip || '').slice(0, 5));
            //setTaxAmount(null);
            setBillingAddress(__assign({}, shippingAddress));
        }
    }, [userProfile]);
    return (React.createElement(DigitalFulfillmentContext.Provider, { value: [
            {
                phone: phone,
                setPhone: setPhone,
                shippingAddress: shippingAddress,
                setShippingAddress: setShippingAddress,
                billingAddress: billingAddress,
                setBillingAddress: setBillingAddress,
                billSameAsShip: billSameAsShip,
                setBillSameAsShip: setBillSameAsShip,
            },
        ] }, children));
};
