import React, { useState } from 'react';
export var CheckoutContext = React.createContext({
    code: null,
});
export var CheckoutContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(1), currentStep = _b[0], setCurrentStep = _b[1];
    var _c = useState(false), spinner = _c[0], setSpinner = _c[1];
    var _d = useState(''), error = _d[0], setError = _d[1];
    return (React.createElement(CheckoutContext.Provider, { value: [
            {
                currentStep: currentStep,
                setCurrentStep: setCurrentStep,
                spinner: spinner,
                setSpinner: setSpinner,
                error: error,
                setError: setError,
            },
        ] }, children));
};
