import React, { useEffect, useState } from 'react';
import ServiceHandler from '../../services/service-handler';
import { Navigate } from 'react-router-dom';
import { isMobile } from '../../services/mobile-bridge/mobile-bridge';
export var IndexRoute = function (_a) {
    var unauth = _a.unauth, auth = _a.auth;
    var _b = useState(null), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var _c = useState(false), mounted = _c[0], setMounted = _c[1];
    var authService = ServiceHandler.AuthenticationService;
    useEffect(function () {
        authService.onIsAuthenticated().then(function (isAuthenticated) {
            setIsAuthenticated(isAuthenticated);
            setMounted(true);
        });
    }, [authService]);
    if (!mounted && !isMobile())
        return React.createElement("div", null);
    return (React.createElement(Navigate, { replace: true, to: {
            pathname: isAuthenticated || isMobile() ? auth : unauth,
            search: window.location.search,
        } }));
};
