import React from 'react';
import styles from './details-view/rewards-details.module.scss';
import utils from '../../utils/utils.module.scss';
import { formatMoney, formatPoints } from '../../utils/format.utils';
import cx from '../../utils/classnames';
import { OffersTileContents } from './dashboard-view/offers-tile/offers-tile';
export var RewardGroup = function (_a) {
    var _b;
    var rewardGroup = _a.rewardGroup, _c = _a.limit, limit = _c === void 0 ? 99999 : _c, desktopCTA = _a.desktopCTA, mobileCTA = _a.mobileCTA, parameters = _a.parameters, className = _a.className;
    return (React.createElement(React.Fragment, null,
        React.createElement("ul", { className: styles.tileContainer }, (_b = rewardGroup === null || rewardGroup === void 0 ? void 0 : rewardGroup.slice(0, limit)) === null || _b === void 0 ? void 0 : _b.map(function (reward) { return (React.createElement(OffersTileContents, { key: reward.rewardCode, rewardOffer: reward, mobileCTATitle: mobileCTA, desktopCTATitle: desktopCTA, parameters: getParams(reward.rewardCode, parameters), className: className })); }))));
};
export var RewardsContainerWithImage = function (_a) {
    var rewardOffer = _a.rewardOffer, children = _a.children;
    return (React.createElement("div", { className: styles.mainContainer },
        React.createElement("div", { className: cx(utils.centerTop, styles.contentContainer) },
            React.createElement("div", { className: utils.twoColumn },
                React.createElement("div", { className: cx(utils.column, styles.imageContainer) },
                    rewardOffer.quantityAvailable != null && rewardOffer.quantityAvailable < 16 && rewardOffer.quantityAvailable != 0 && (React.createElement("div", { className: styles.quantity },
                        rewardOffer.quantityAvailable,
                        " Remaining")),
                    React.createElement("img", { className: styles.image, src: rewardOffer.detailsImageURL, alt: rewardOffer.detailsImageAltTag }),
                    rewardOffer.detailsImageOverlayText && React.createElement("div", { className: styles.tierExclusive }, rewardOffer.detailsImageOverlayText)),
                React.createElement("div", { className: cx(utils.column, styles.titleContainer) }, children)))));
};
export var filterRewards = function (rewardCategory, rewardGroup) {
    var rewardFilter = rewardGroup.filter(function (x) { return x.rewardCategory == rewardCategory; });
    return sortRewards(rewardFilter);
};
export var sortRewards = function (rewardGroup) {
    return rewardGroup.sort(function (a, b) { return a.catalogPosition - b.catalogPosition; });
};
var getParams = function (rewardCode, searchParams) {
    searchParams.delete('rewardCode');
    searchParams.append('rewardCode', rewardCode);
    return searchParams.toString();
};
export var PointsAndMoney = function (_a) {
    var points = _a.points, money = _a.money;
    return (React.createElement(React.Fragment, null,
        +points > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: utils.bold },
                formatPoints(+points),
                " Points"),
            React.createElement("span", null, +money > 0 && " (Or pay up to ".concat(formatMoney(+money), ")")))),
        +money > 0 && !+points && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: utils.bold },
                formatMoney(+money),
                " Cash"),
            React.createElement("span", null)))));
};
