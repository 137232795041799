import ConfigService from './config-service/config-service';
import { MobileBridgeService } from './mobile-bridge/mobile-bridge';
import { resolveAuthenticationService } from './authentication-service/authentication-service-resolver';
var mobileService = new MobileBridgeService();
var ServiceHandler = {
    MobileService: mobileService,
    AuthenticationService: resolveAuthenticationService(mobileService),
    ConfigService: new ConfigService(),
};
export default ServiceHandler;
