import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAmplitude } from '../../hooks/use-amplitude';
export var NoMatchRoute = function (_a) {
    var to = _a.to;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var location = useLocation();
    useEffect(function () {
        logAmplitudeEvent('404', { path: location.pathname });
        window.location.href = to;
    }, [location]);
    return React.createElement(React.Fragment, null);
};
