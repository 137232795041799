//////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////
import { createLocalFontFaceDefinition } from './fpr-fontface-local-factory'; // eslint-disable-line no-redeclare
import { createRemoteFontFaceDefinition } from './fpr-fontface-remote-factory'; // eslint-disable-line no-redeclare
//////////
import { isMobile } from '../mobile-bridge/mobile-bridge';
export var loadFonts = function (brandId, assetsPath) {
    var styleElement = document.createElement('style');
    var remotePathname = window.location.hostname.includes('wwwuat') ? '' : '/support';
    var fonts = process.env.REACT_APP_MODE == 'none' || isMobile()
        ? createLocalFontFaceDefinition("".concat(assetsPath, "/content/dam/loyalty/common/fonts"))
        : createRemoteFontFaceDefinition("".concat(window.location.hostname).concat(remotePathname));
    styleElement.innerHTML = fonts;
    document.body.appendChild(styleElement);
};
