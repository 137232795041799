import React, { useState, useEffect } from 'react';
import PointsBalanceService from '../services/points-balance-service/points-balance-service';
import ServiceHandler from '../services/service-handler';
export var PointsHeroContext = React.createContext([
    {
        totalPoints: null,
        latestTransaction: undefined,
        tier: null,
    },
]);
export var PointsHeroContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), totalPoints = _b[0], setTotalPoints = _b[1];
    var latestTransaction = useState()[0];
    var _c = useState(null), pointExpirationDate = _c[0], setPointExpirationDate = _c[1];
    var _d = useState('A'), memberStatus = _d[0], setMemberStatus = _d[1];
    var _e = useState(null), tier = _e[0], setTier = _e[1];
    var _f = useState(0), isDesktop = _f[0], setIsDesktop = _f[1];
    var errorMessage = useState('')[0];
    var reloadPoints = function () {
        var balanceService = new PointsBalanceService();
        balanceService.request().then(function (response) {
            var brand = response === null || response === void 0 ? void 0 : response.data[ServiceHandler.ConfigService.BRAND_ID == 'fpr' ? 'ford' : 'lincoln'];
            if (brand) {
                setTier(brand);
                setMemberStatus(brand.memberStatus);
                setTotalPoints(brand.points);
                setPointExpirationDate(brand.pointsExpirationDate);
            }
        });
    };
    useEffect(function () {
        if (totalPoints == null && ServiceHandler.AuthenticationService.getIsAuthenticated()) {
            reloadPoints();
        }
    }, []);
    return (React.createElement(PointsHeroContext.Provider, { value: [{ totalPoints: totalPoints, pointExpirationDate: pointExpirationDate, latestTransaction: latestTransaction, tier: tier, memberStatus: memberStatus, reloadPoints: reloadPoints, errorMessage: errorMessage, isDesktop: isDesktop, setIsDesktop: setIsDesktop }] }, children));
};
