import React, { useEffect, useContext } from 'react';
import { useContent } from '../../../hooks/use-content';
import { CountryEligibilitysContext } from '../../../context/country-eligibilty-context';
import PointsBalanceService from '../../../services/points-balance-service/points-balance-service';
import { APIHelperContext } from '../../../context/api-helper-context';
import ServiceHandler from '../../../services/service-handler';
import { CtaButton, CtaSecondaryButton } from '../../common/cta/cta';
import styles from './country-selector.module.scss';
import { useLocation } from 'react-router-dom';
export var CountrySelector = function (_a) {
    var setOverlay = _a.setOverlay;
    var getValueByTitle = useContent('country-selector')[0].getValueByTitle;
    var _b = useContext(CountryEligibilitysContext), eligible = _b[0], setEligible = _b[1];
    var apiHelper = useContext(APIHelperContext)[0];
    var cfg = ServiceHandler.ConfigService;
    var params = new URLSearchParams(useLocation().search);
    useEffect(function () {
        var _a;
        if (params.get('change')) {
            cfg.PROGRAM_COUNTRY = (_a = params.get('country')) === null || _a === void 0 ? void 0 : _a.toUpperCase();
            cfg.LANGUAGE_COUNTRY_CODE = cfg.LANGUAGE_COUNTRY_CODE == 'fr-ca' && params.get('language') == 'en-ca' ? 'fr-ca' : params.get('language');
            cfg.COUNTRY_CODE = cfg.LANGUAGE_COUNTRY_CODE.split('-')[1];
        }
        if (ServiceHandler.AuthenticationService.getIsAuthenticated() && eligible == null) {
            var balanceService = new PointsBalanceService();
            balanceService.setCountryCode(cfg.PROGRAM_COUNTRY == 'CAN' ? 'USA' : 'CAN');
            balanceService.requestElibibility(apiHelper).then(function (eligible) {
                setEligible(eligible);
            });
        }
    }, [ServiceHandler.AuthenticationService.getIsAuthenticated()]);
    return (React.createElement(React.Fragment, null, eligible ? (React.createElement(React.Fragment, null,
        React.createElement("div", { onMouseDown: function (e) {
                e.preventDefault();
                setOverlay(true);
            }, className: styles.flag }, getValueByTitle('country1')))) : null));
};
export var CountrySelectorSpeedBump = function (_a) {
    var setOverlay = _a.setOverlay;
    var getValueByTitle = useContent('country-selector')[0].getValueByTitle;
    var cfg = ServiceHandler.ConfigService;
    var changeCountry = function () {
        var url = "".concat(window.location.origin, "?brand=").concat(cfg.BRAND_NAME, "&language=").concat(cfg.LANGUAGE_COUNTRY_CODE == 'en-us' ? 'en-ca' : 'en-us', "&country=").concat(cfg.PROGRAM_COUNTRY == 'USA' ? 'can' : 'usa', "&change=true");
        window.location.href = url;
    };
    return (React.createElement("div", { className: styles.overlay },
        React.createElement("div", { className: styles.overlayTitle }, getValueByTitle('overlay-title')),
        React.createElement("div", { className: styles.overlaySubTitle }, getValueByTitle('overlay-subtitle')),
        React.createElement("div", { className: styles.overlayReturnMessage }, getValueByTitle('return-message')),
        React.createElement("div", null,
            React.createElement(CtaButton, { className: styles.buttonContinue, id: "countrySel ".concat(cfg.PROGRAM_COUNTRY == 'USA' ? 'can' : 'usa'), label: getValueByTitle('continue'), onClick: changeCountry })),
        React.createElement("div", null,
            React.createElement(CtaSecondaryButton, { id: 'cancelCountry', label: getValueByTitle('cancel'), onClick: function () { return setOverlay(false); } }))));
};
export default CountrySelector;
