var MobileAuthenticationService = /** @class */ (function () {
    function MobileAuthenticationService(nativeBridge) {
        var _this = this;
        this.nativeBridge = nativeBridge;
        this.catBundle = null;
        this.getCatBundlePromise = new Promise(function (resolve) {
            if (_this.alreadyCalledGetCatBundle) {
                resolve(_this.catBundle);
            }
            else {
                _this.nativeBridge.getCatBundle().then(function (catBundle) {
                    _this.catBundle = catBundle;
                    _this.alreadyCalledGetCatBundle = true;
                    resolve(catBundle);
                });
            }
        });
    }
    MobileAuthenticationService.prototype.getCatBundle = function () {
        return this.catBundle;
    };
    MobileAuthenticationService.prototype.login = function () {
        // method not defined for mobile
    };
    MobileAuthenticationService.prototype.logout = function () {
        // method not defined for mobile
    };
    MobileAuthenticationService.prototype.onIsAuthenticated = function () {
        return this.getCatBundlePromise.then(function (x) { return !!x; });
    };
    MobileAuthenticationService.prototype.getIsAuthenticated = function () {
        if (!this.alreadyCalledGetCatBundle) {
            throw new Error('getIsAuthenticated is being called before the authentication check has completed. This function should only be called in code that has been wrapped inside onIsAuthenticated');
        }
        return !!this.catBundle;
    };
    return MobileAuthenticationService;
}());
export { MobileAuthenticationService };
