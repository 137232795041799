import { AndroidBridge } from './android-bridge';
import { IOsBridge } from './ios-bridge';
export function isAndroidBridgeAvailable() {
    return !!window['android'];
}
export function iOSBridgeAvailable() {
    return !!(window['webkit'] && window['webkit']['messageHandlers'] && window['webkit']['messageHandlers']['pacific']);
}
export function isMobile() {
    return iOSBridgeAvailable() || isAndroidBridgeAvailable();
}
var MobileBridgeService = /** @class */ (function () {
    function MobileBridgeService() {
        if (isAndroidBridgeAvailable()) {
            this._nativeBridge = new AndroidBridge();
        }
        else if (iOSBridgeAvailable()) {
            this._nativeBridge = new IOsBridge();
        }
    }
    Object.defineProperty(MobileBridgeService.prototype, "nativeBridge", {
        get: function () {
            return this._nativeBridge;
        },
        enumerable: false,
        configurable: true
    });
    return MobileBridgeService;
}());
export { MobileBridgeService };
