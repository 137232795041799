var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import { Link } from 'react-router-dom';
import cx from '../../../utils/classnames';
import common from '../../../utils/common.module.scss';
import { useAmplitude } from '../../../hooks/use-amplitude';
import ServiceHandler from '../../../services/service-handler';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
import { ActivityIndicator } from '../activity-indicator/activity-indicator';
export var CLICK_ONCE_FLAG = 'FPRrewardsClickOnceFlag';
import { SOCT_URL } from '../../../constants';
export var defaultArrowProps = {
    type: 'unfilled',
    direction: 'right',
    arrow: true,
};
export var CtaLink = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: common.link, arrowProperties: { arrow: true } }));
};
export var CtaTextLink = function (propsCta) {
    return (React.createElement(Cta, __assign({}, propsCta, { class: 'fmc-text-button', className: common.ctaTextLink, arrowProperties: { arrow: propsCta.arrow != null ? propsCta.arrow : true } })));
};
export var CtaNoArrorLink = function (propsCta) {
    return React.createElement(Cta, __assign({}, __assign(__assign({}, propsCta), { arrow: false }), { className: common.ctaTextLink }));
};
export var CtaButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: "".concat(common.buttonPrimary, " ").concat(propsCta.loading && common.redeemButtonSpinner) }));
};
export var CtaSecondaryButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: common.buttonOutlinePrimary }));
};
export var CtaWhiteOutlineButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: common.buttonWhiteOutline }));
};
export var CtaLinkButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, __assign(__assign({}, propsCta), { arrow: false }), { className: common.buttonOutlinePrimary }));
};
export var CtaWhiteButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: "".concat(cx(common.buttonPrimary, common.whiteButton), " ").concat(propsCta.loading && common.redeemButtonSpinner) }));
};
export var Cta = function (propsCta) {
    var _a;
    var _b;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var sanatizedLocationSearch = useNavigateTo().sanatizedLocationSearch;
    var _c = useState(false), pressed = _c[0], setPressed = _c[1];
    var handleOnClick = function (e) {
        if ((pressed && !propsCta.allowDblClick) || propsCta.disabled) {
            e.preventDefault();
            return;
        }
        logAmplitudeEvent("cta tapped", {
            ctaName: propsCta.id || propsCta.ariaLabel || propsCta.label,
            labelText: propsCta.ariaLabel || propsCta.label,
        });
        if (propsCta.onClick) {
            setPressed(true);
            e.preventDefault();
            e.stopPropagation();
            if (!propsCta.disabled)
                propsCta.onClick();
            var elem = document.activeElement;
            elem.blur();
        }
    };
    var href = (_b = ((propsCta.href || ServiceHandler.ConfigService.APP_BASEPATH + propsCta.link) + sanatizedLocationSearch(propsCta.search))) === null || _b === void 0 ? void 0 : _b.replace(/{LINCOLN_SOCT_URL}|{FORD_SOCT_URL}|{SOCT_URL}/, SOCT_URL);
    if (!propsCta.href && !propsCta.link)
        href = undefined;
    if (href == 'undefined')
        href = undefined;
    if (!(propsCta.label || propsCta.children))
        return null; //prevents 'flashing'
    return (React.createElement("span", { onClick: handleOnClick, tabIndex: 0, className: cx((_a = {}, _a[common.disabled] = (!propsCta.allowDblClick && pressed) || propsCta.disabled, _a)) }, !propsCta.href && propsCta.link ? (React.createElement(Link, { to: propsCta.disabled
            ? {}
            : {
                pathname: propsCta.link,
                search: sanatizedLocationSearch(propsCta.search),
            }, replace: false, className: propsCta.className, "aria-label": propsCta.ariaLabel || propsCta.label, id: propsCta.id, target: propsCta.target },
        propsCta.children ? propsCta.children : React.createElement("span", null, propsCta.label),
        propsCta.arrow != false && React.createElement(FdsChevron, { className: propsCta.chevronClassName, direction: 'right' }))) : (React.createElement("a", { href: href, "aria-label": propsCta.ariaLabel || propsCta.label, id: propsCta.id, className: propsCta.className, target: propsCta.target },
        propsCta.children ? propsCta.children : propsCta.loading ? React.createElement(ActivityIndicator, { size: 'S' }) : React.createElement("span", null, propsCta.label),
        propsCta.arrow != false && React.createElement(FdsChevron, { className: propsCta.chevronClassName, direction: 'right' })))));
};
