import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import ServiceHandler from './services/service-handler';
import { loadFonts } from './services/font-loader/font-loader';
import './index.scss';
ServiceHandler.ConfigService.initDataAttributes();
loadFonts(ServiceHandler.ConfigService.BRAND_ID, ServiceHandler.ConfigService.AEM_BASE_URL);
ReactDOM.render(React.createElement(React.StrictMode, null,
    React.createElement(App, null)), document.getElementById('loyalty-webpages-container'));
