import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAmplitude } from '../../hooks/use-amplitude';
export var RedirectRoute = function (_a) {
    var to = _a.to, scrollTo = _a.scrollTo;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var location = useLocation();
    useEffect(function () {
        logAmplitudeEvent('redirect', { path: location.pathname, to: to });
    }, [location]);
    var search = window.location.search;
    if (scrollTo)
        search += "".concat(search ? "&" : '?', "ref=").concat(scrollTo);
    return (React.createElement(Navigate, { replace: true, to: {
            pathname: to,
            search: search,
        } }));
};
