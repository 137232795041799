var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable @typescript-eslint/no-namespace */
import React, { useContext, useState } from 'react';
import AreYouSure from '../are-you-sure/are-you-sure';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
import styles from './dangerous-html.module.scss';
import { useNavigateTo } from '../../../hooks/use-navigateTo';
import { useAmplitude } from '../../../hooks/use-amplitude';
import amplitude from 'amplitude-js';
import { SOCT_URL } from '../../../constants';
import { CtaLink } from '../cta/cta';
import { CreditCardEligibilityContext } from '../../../context/credit-card-eligibility-context';
import ServiceHandler from '../../../services/service-handler';
export var launchExternalLink = function (href) {
    amplitude.getInstance().logEvent('rewards launch external', { href: href });
    return isMobile() ? (window.location.href = href) : window.open(href);
};
export var DangerousHtml = function (_a) {
    var html = _a.html, className = _a.className;
    var navigateTo = useNavigateTo().navigateTo;
    var logAmplitudeEvent = useAmplitude().logAmplitudeEvent;
    var _b = useState(''), showSpeedbump = _b[0], setShowSpeedbump = _b[1];
    var isCardholder = useContext(CreditCardEligibilityContext)[0];
    var onClickSpeedbump = function (href) {
        var found = false;
        var vanityUrls = ['fordpassrewards.com', 'lincolnaccessrewards.com'];
        var internal = ['/fordpass/fordpass-rewards/', '/lincoln-way-app/lincoln-access-rewards/'];
        var whiteList = ['ford.com', 'lincoln.com', 'fordpass.com'];
        logAmplitudeEvent('link cta pressed', { url: href });
        if (href.startsWith('/'))
            return navigateTo(href);
        //  fordpassrewards.com', 'lincolnaccessrewards.com
        vanityUrls.forEach(function (item) {
            if (href.toLowerCase().includes(item)) {
                found = true;
                return navigateTo('/overview');
            }
        });
        if (found)
            return;
        //  rewards/fordpass-rewards/', '/lincoln-way-app/lincoln-access-rewards/
        internal.forEach(function (item) {
            if (href.toLowerCase().includes(item)) {
                found = true;
                return navigateTo(href.replace(/.*?\/(fordpass|lincoln-access)-rewards\/(.*)/, '/$2'));
            }
        });
        if (found)
            return;
        ///  accesories, subscriptions...etc
        whiteList.forEach(function (item) {
            if (href.toLowerCase().includes(item)) {
                found = true;
                if (href.includes('||')) {
                    return launchExternalLink(isMobile() ? href.split('||')[1] : href.split('||')[0]);
                }
                return launchExternalLink(href);
            }
        });
        if (found)
            return;
        //extern
        setShowSpeedbump(href);
    };
    window['onClickSpeedbump'] = onClickSpeedbump;
    var onContinue = function () {
        launchExternalLink(showSpeedbump);
        setShowSpeedbump('');
    };
    var magic_soct = function () {
        return ServiceHandler.AuthenticationService.getIsAuthenticated() && isCardholder
            ? 'Summary of Credit Terms'
            : "<a onClick=\"onClickSpeedbump('".concat(SOCT_URL, "')\" aria-label='view summary of credit terms'>Summary of Credit Terms</a>");
    };
    return (React.createElement(React.Fragment, null,
        html && (React.createElement("div", { className: className ? className : styles.links, dangerouslySetInnerHTML: {
                __html: html
                    .replace(/{MAGIC_SOCT_URL}/g, magic_soct())
                    .replace(/{SOCT_URL}/g, SOCT_URL)
                    .replace(/href=['"](.*?)['"].*?>/g, 'onClick="onClickSpeedbump(\'$1\')" >'),
            } })),
        showSpeedbump && (React.createElement(AreYouSure, { contentFragment: 'leaving-ford', href: showSpeedbump, onContinue: function () { return onContinue(); }, onCancel: function () { return setShowSpeedbump(''); } }))));
};
export var DangerousHtmlLink = function (propsCta) {
    var _a = useState(''), showSpeedbump = _a[0], setShowSpeedbump = _a[1];
    var onContinue = function () {
        launchExternalLink(showSpeedbump);
        setShowSpeedbump('');
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CtaLink, __assign({}, __assign(__assign({}, propsCta), { allowDblClick: true, href: null, onClick: function () {
                setShowSpeedbump(propsCta.href);
            } }))),
        showSpeedbump && (React.createElement(AreYouSure, { contentFragment: 'leaving-ford', href: showSpeedbump, onContinue: function () { return onContinue(); }, onCancel: function () { return setShowSpeedbump(''); } }))));
};
