import React from 'react';
import cx from '../../../utils/classnames';
import styles from './navDropdown.module.scss';
function Tab(_a) {
    var _b;
    var children = _a.children, active = _a.active, setTab = _a.setTab, id = _a.id, className = _a.className, authed = _a.authed;
    return (React.createElement("button", { role: 'tab', className: cx(styles.tab, (_b = {}, _b[cx(styles.active, 'active')] = active, _b), className), onMouseDown: function () {
            document.activeElement.blur();
            setTab(id, authed);
        }, onKeyDown: function (e) {
            if (e.code === 'Enter') {
                e.preventDefault();
                setTab(id, authed);
            }
        }, "aria-selected": active }, children));
}
export default Tab;
