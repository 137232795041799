import { useNavigate } from 'react-router-dom';
import ServiceHandler from '../services/service-handler';
export var useNavigateTo = function () {
    var navigate = useNavigate();
    var sanatizedLocationSearch = function (params) {
        var searchParams = new URLSearchParams(window.location.search);
        var result = '';
        searchParams.forEach(function (value, key) {
            if (['brand', 'language', 'country', 'stealth', 'utm_source', 'utm_medium'].includes(key))
                if (!params || !params.includes(key))
                    result += "&".concat(key, "=").concat(value);
        });
        // minor hack
        if (window.location.href.includes('/rewards/') && searchParams.get('resultCode'))
            result += "&resultCode=".concat(searchParams.get('resultCode'));
        if (params)
            result += "&".concat(params.toString().replace('?', ''));
        return result.substr(1) ? '?' + result.substr(1) : '';
    };
    var authNavigateTo = function (pathname, params) {
        var isLocal = window.location.host.includes('local') || window.location.search.includes('admin=override');
        if (isLocal || ServiceHandler.AuthenticationService.getIsAuthenticated())
            return navigateTo(pathname, params);
        var url = window.location.href.replace(/(\/support\/.*?\/.*?)\/.*/, "$1".concat(pathname));
        window.location.href = url;
    };
    var navigateTo = function (pathname, params) {
        if (pathname.includes('?')) {
            params = pathname.split('?')[1];
            pathname = pathname.split('?')[0];
        }
        navigate({
            pathname: pathname,
            search: sanatizedLocationSearch(params === null || params === void 0 ? void 0 : params.toString()),
        }, {
            state: {
                pathname: pathname,
                from: window.location.href
                    .replace(/https.*?\/support\/.*?\/.*?\/|https:\/\/local.globalowner.ford.com\/|https:\/\/loyalty-webpages-react.*?\//, '/')
                    .replace(/\?.*/, ''),
            },
        });
    };
    return { sanatizedLocationSearch: sanatizedLocationSearch, navigateTo: navigateTo, authNavigateTo: authNavigateTo };
};
