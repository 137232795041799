import React, { useEffect } from 'react';
import { useAmplitude } from '../../hooks/use-amplitude';
import { Outlet } from 'react-router-dom';
import { setPageTitle } from '../../utils/url.utils';
export var LogRoute = function () {
    var _a = useAmplitude(), logAmplitudeEvent = _a.logAmplitudeEvent, getAmplitudeScreenTitle = _a.getAmplitudeScreenTitle;
    useEffect(function () {
        var _a;
        if (window.location.pathname) {
            setPageTitle();
            var params = new URLSearchParams(window.location.search);
            logAmplitudeEvent("".concat(getAmplitudeScreenTitle() || 'dashboard', " page viewed"), {
                param: params.get('rewardCode') || params.get('ref') || params.get('error') || undefined,
                pointsAssistError: (_a = params.get('result')) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(),
            });
        }
    }, [window.location.pathname]);
    // (async () => {
    //   const response = await fetch(`${process.env.PUBLIC_PATH || ''}meta.json?t=${new Date().getTime()}`, {
    //     cache: 'no-store',
    //   });
    //   let remoteVer = await response.text();
    //   remoteVer = remoteVer.trim();
    //   if (response.status == 200 && remoteVer?.length && BrowserAuthenticationService.VERSION_STAMP.trim() !== remoteVer) {
    //     logAmplitudeEvent('cache clear', { loyaltyVerNew: remoteVer });
    //     if (caches) {
    //       const names = await caches.keys();
    //       await Promise.all(names.map(name => caches.delete(name)));
    //     }
    //     sessionStorage.clear();
    //     window.location.reload();
    //   }
    // })();
    return React.createElement(Outlet, null);
};
